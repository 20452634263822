import React, { FC, Fragment } from "react"
import Wrapper from "../../shared/wrapper"
import styled from "@emotion/styled"
import tw from "twin.macro"
import ReactHtmlParser from "react-html-parser"
// Components
import SectionHeader from "../../shared/sectionHeader"
import Underline from "../../shared/underline"
import HeaderTagline from "../../shared/headerTagling"
// Interafces
import { IAboutSection } from "../../../interfaces/about"

const AboutWrapper = styled.section`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 86%);
  ${tw`pb-10 bg-lightBlack`}
`
const AboutContentWrapper = styled.section`
  ${tw`md:flex flex-1 mt-12`};
  div:nth-of-type(1){
    ${tw`md:pr-10 md:mb-0 mb-10`}
  }
  div {
      p {
        ${tw`text-DarkWhite`}
      }
  }

`

const AboutMe: FC<IAboutSection> = (props) => {
  return (
    <Fragment>
      <div className="bg-black">
        <AboutWrapper>
          <Wrapper py={true} mx={true}>
            <div className="pb-10">
              <div className="flex justify-center items-center flex-col">
                <SectionHeader text="about me" />
                <HeaderTagline
                  classsName="text-DarkWhite"
                  text={props.tagline}
                />
                <Underline />
                {/* <div className="w-52 bg-white pt-1 mt-8"></div> */}
              </div>
              <AboutContentWrapper >
                {ReactHtmlParser(props.description.description)}
              </AboutContentWrapper>
            </div>
          </Wrapper>
        </AboutWrapper>
      </div>
    </Fragment>
  )
}

export default AboutMe
