import React, { FC } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
// Interfaces
import { IHeroSection } from "../../../interfaces/hero"
// Images
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
// Components
import Wrapper from "../../shared/wrapper"

const HeroContainer = styled.section`
  min-height: 500px;
  ${tw`flex h-screen w-full items-center`}
`



const Hero: FC<IHeroSection> = (props) => {


  const img = getImage(props.backgroundImage)
  const bgImg = convertToBgImage(img)
  return (
    <React.Fragment>
      <BackgroundImage
        className="w-full h-screen bg-center bg-fixed"
        {...bgImg}
        preserveStackingContext
        Tag="section"
      >
        <HeroContainer>
          <Wrapper mx={true}>
            <div className="text-center">
              <h4 className="text-white ">AVADA FREELANCE</h4>
              <div className="my-6">
                <h1 className="text-primary lg:text-6xl xl:text-9xl text-5xl sm:text-6xl">
                  Art Director
                </h1>
              </div>
              <h5 className="text-DarkWhite">
                IF IT DOESN’T CHALLENGE YOU, IT DOESN’T CHANGE YOU
              </h5>
            </div>
          </Wrapper>
        </HeroContainer>
      </BackgroundImage>
    </React.Fragment>
  )
}

export default Hero
