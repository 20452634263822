import React, { FC, Fragment } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
// Componenets
import HeaderTagline from "../../shared/headerTagling"
import SectionHeader from "../../shared/sectionHeader"
import Underline from "../../shared/underline"
import Wrapper from "../../shared/wrapper"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import ProjectBox from "../../shared/projectBox"
import { IRecentWorkSection } from "../../../interfaces/projects"

const ImageWrapper = styled(motion.div)`
  ${tw` relative flex justify-center cursor-pointer `};
`
const ImageLayout = styled(motion.div)`
  ${tw`absolute h-full bg-primary z-10 overflow-hidden  opacity-40 flex justify-center items-center cursor-pointer`};
`

const LayoutMotion = {
  rest: {
    opacity: 0,
    width: "0px",
    ease: "easeOut",
    duration: 0.4,
    type: "tween",
  },
  hover: {
    width: "100%",
    opacity: "90%",
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
}
const RecentWork: FC<IRecentWorkSection> = (props) => {
  return (
    <Fragment>
      <div className="bg-green ">
        <Wrapper mx={true} py={true}>
          <div className="flex flex-col items-center text-white">
            <SectionHeader text="Recent Work" />
            <HeaderTagline
              classsName="text-white"
              text="WEB • BRANDING • UX/UI"
            />
            <Underline />
          </div>
        </Wrapper>
        <div className="flex flex-wrap">
          {
            props.project.map((project) => (

              <ProjectBox url={project.slug} text={project.header} image={project.image.gatsbyImageData} />
            ))
          }
          {/* <ProjectBox url="/" text=" A BAG for life" image="p2" /> */}
        </div>
        {/* View Work Button */}
        <Link to="work">
          <div className="w-full bg-primary hover:bg-white hover:text-primary text-white transition cursor-pointer duration-300 ">
            <Wrapper mx={true}>
              <h5 className=" sm:py-16 py-6 text-center">View All Work</h5>
            </Wrapper>
          </div>
        </Link>
      </div>
    </Fragment>
  )
}

export default RecentWork
