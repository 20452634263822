import React, { Fragment, useState, FC } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
// Components
import HeaderTagline from "../../shared/headerTagling"
import SectionHeader from "../../shared/sectionHeader"
import Underline from "../../shared/underline"
import Wrapper from "../../shared/wrapper"
import { FaGem, FaGitlab, FaShieldAlt } from "react-icons/fa"
// Interfaces
import { IServicesSection } from "../../../interfaces/services"

const ParagraphWrapper = styled.section`
  max-width: 800px;
  ${tw`mt-10 text-center`}
`
const ServicesWrapper = styled.section`
  clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
  ${tw`bg-black pb-16`}
`

const Services: FC<IServicesSection> = (props) => {
  const [icons] = useState([
    { iconName: "design", text: "WEB DESIGN" },
    { iconName: "branding", text: "BRANDING" },
    { iconName: "ui", text: "UI / UX" },
  ])
  return (
    <Fragment>
      <div className="bg-green">
        <ServicesWrapper>
          <Wrapper mx={true} py={true}>
            <div className="py-4">
              <div className="text-center flex flex-col items-center">
                <SectionHeader text={props.header} />
                <HeaderTagline
                  classsName="text-DarkWhite"
                  text={props.tagline}
                />
                <Underline />
              </div>
              <div className="flex justify-center">
                <ParagraphWrapper>
                  <p className="text-DarkWhite">
                    {props.description.description}
                  </p>
                </ParagraphWrapper>
              </div>
              <div className="pt-16 md:flex justify-center">
                {props.services.map(icon => (
                  <div className="w-full  flex flex-col items-center md:mb-0 mb-10">
                    <div className="bg-primary rounded-full p-2">
                      <div className="bg-black rounded-full p-10">
                        {icon.iconName === "web" ? (
                          <FaGitlab className="text-7xl text-DarkWhite" />
                        ) : icon.iconName === "ui" ? (
                          <FaGem className="text-7xl text-DarkWhite" />
                        ) : icon.iconName === "branding" ? (
                          <FaShieldAlt className="text-7xl text-DarkWhite" />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <h6 className="mt-7 text-white">{icon.serviceHeader}</h6>
                  </div>
                ))}
              </div>
            </div>
          </Wrapper>
        </ServicesWrapper>
      </div>
    </Fragment>
  )
}

export default Services
