import { graphql, useStaticQuery } from "gatsby"

export const getHomeData = () => {
  const { homeData } = useStaticQuery(query)
  return homeData
}


export const query = graphql`
  {
    homeData:contentfulHomePage {
      page
      hero {
        header
        tagline
        shortDescription
        backgroundImage {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      aboutSection {
        header
        tagline
        description {
          description
        }
      }
      servicesSection {
        header
        tagline
        description {
          description
        }
        services {
          iconName
          serviceHeader
        }
      }
      recentWorkSection {
        project {
          slug
          header
          image {
            gatsbyImageData(placeholder:BLURRED)
          }
        }
      }
    }
  }
`
