import React, { Fragment } from "react"
// Services
import { getHomeData } from "../services/home"
// Interfaces
import { IHome } from "../interfaces/home"
// Components
import Seo from "../components/shared/seo"
import Layout from "../components/layout/layout"
import Hero from "../components/sections/home/hero"
import AboutMe from "../components/sections/home/about"
import Services from "../components/sections/home/services"
import RecentWork from "../components/sections/home/recentWork"


const Index = () => {
  const data: IHome = getHomeData()
  return (
    <Fragment>
      <Seo title="GatsWind Starter Plus" />
      <Layout>
        <Hero {...data.hero} />
        <AboutMe {...data.aboutSection} />
        <Services {...data.servicesSection} />
        <RecentWork {...data.recentWorkSection} />
      </Layout>
    </Fragment>
  )
}

export default Index
